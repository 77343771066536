import React from "react"
import { graphql, navigate } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Dimmer, Grid, Loader } from "semantic-ui-react";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { GenericBannerContainer } from "@components/GenericBannerContainer";
import { H2, ErrorContainer } from "@styles/Global.styles";
import { mapToOrderReviewSummary } from "@components/OrderReviewDetails";
const queryString = require('query-string');
import { OrderReviewSummaryData } from "@components/OrderReviewDetails/mapper";
import { OrderConfirmationService } from "@services/orderConfirmationService";
import { OrderConfirmationDataModel } from "@models/OrderConfirmation";
import { OrderReviewSummaryDetails } from "@components/OrderReviewDetails/OrderReviewSummaryDetails";
import { mapTransactionConfirmationToLaybyQuote } from "@models/Layby/LaybyQuote";
import { mapTransactionResponseToBasketResponseModel } from "@models/Basket/BasketResponseModel";
import RetryPayment from "@components/MakePayment/RetryPayment";
import { ErrorHeading } from "@components/PlateDesignDetailsContainer/PlateDesignDetails.styles";
import { encodeURIParameterIfNotEncoded, isMobile } from "@utils/Helpers";
import MobileRetryPayment from "@components/Mobile/MakePayment/RetryPayment";
import { MobileOrderReviewSummaryDetails } from "@components/Mobile/OrderReviewDetails/OrderReviewSummaryDetails";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
import { getCurrentCheckout } from "@redux/localStorage/checkout";
interface ReviewOrderProps {
  location: any,
  data: any
}

interface ReviewOrderStates {
  transactionDetails: OrderConfirmationDataModel | undefined,
  selectedPaymentOption: number,
  paymentFailMessage: string,
  transactionId?: number,
  guid?: string,
  loading: boolean,
  giftType: number
}


class ReviewOrderPage extends React.Component<ReviewOrderProps, ReviewOrderStates> {

  constructor(props: ReviewOrderProps) {
    super(props);
    this.state = {
      transactionDetails: undefined,
      selectedPaymentOption: 1,
      paymentFailMessage: '',
      transactionId: undefined,
      guid: undefined,
      loading: true
    }
  }
  mapTransactionTypeCodeToPaymentOption = (paymentType: string) => {
    if (paymentType == 'OnlineCCAuthAndPurchase' || paymentType == 'OnlineA2A_Partial') {
      this.setState({ selectedPaymentOption: 1 });
    } else if (paymentType == 'OnlineA2A_Full') {
      this.setState({ selectedPaymentOption: 2 });
    } else if (paymentType == 'AfterPay') {
      this.setState({ selectedPaymentOption: 3 });
    } else {
      this.setState({ selectedPaymentOption: 1 });
    }
  }
  componentDidMount() {

    const query = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });
    const transactionId = query.rcpt;
    const id = query.key.replaceAll(' ', '+');
    const success = query.success;
    const allowRetry = query.allowretry;
    const error = query.msg;
    if (success == 1) {
      const event = query.event;
      navigate("/payment-processing/?success=1&rcpt=" + transactionId + "&key=" + id + "&event=" + event);
    }
    if (success == 0 && allowRetry == 1) {
      const paymentMethod = query.paymentmethod ? query.paymentmethod : undefined;
      if (paymentMethod !== undefined) {
        this.mapTransactionTypeCodeToPaymentOption(paymentMethod);
      }

      this.setState({ paymentFailMessage: error });
      //get the transaction deatils
      OrderConfirmationService.getOrderConfirmationData(this.props.data.site.siteMetadata.apiUrl, encodeURIParameterIfNotEncoded(id)).then(data => {
        if (data.data.Data !== null) {

          const orderTransactionDetails = data.data.Data as OrderConfirmationDataModel;
          this.setState({ transactionDetails: orderTransactionDetails, transactionId: transactionId, guid: id });
          const orderCreatedOn = orderTransactionDetails.CreatedOn
        }
        this.setState({ loading: false });
      })
    }
    this.setState({ loading: false });
  }
  renderErrorMessage = (errorMessage: string) => {
    let resultErrorMessage = "";
    switch (errorMessage) {
      case "DO NOT HONOUR":
      case "ERROR - INVLD TRAN":
        resultErrorMessage = "Your Payment has failed. Please refer to your bank issuer for details";
        break;
      case "CARD EXPIRED":
        resultErrorMessage = "Your Payment has failed. It looks like you attempted payment with an expired card.";
        break;
      case "TIMEOUT":
        resultErrorMessage = "Your Payment has failed. Please try again or use another payment option.";
        break;
      case "ERROR - INVLD FORMAT":
        resultErrorMessage = "Your Payment has failed. It looks like there was an issue with your card details.";
        break;
      default:
        resultErrorMessage = "Your Payment has failed. Please try again or use another payment option.";
    }
    return resultErrorMessage;
  }

  swithPaymentOption = (paymentType: number) => {
    this.setState({ selectedPaymentOption: paymentType });
  }
  render() {
    const { elements } = this.props.data.kontentItemReviewPaymentPage;
    const apiUrl = this.props.data.site.siteMetadata.apiUrl;
    const seo = mapToSEO(elements);
    const heading = elements.heading.value;
    const data: OrderReviewSummaryData = mapToOrderReviewSummary(elements);
    const checkout = getCurrentCheckout()

    if(isMobile() === undefined){
      return null;
    }

    return (
      <Layout version="simple" currentStep={5} location={this.props.location}>
        <SEO {...seo} />
        {
          this.state.loading ?
            <Dimmer active>
              <Loader content="Processing..." />
            </Dimmer>
            :
            <MobileGenericBannerContainer
              padding={{
                mobile: {
                  top: 20,
                  bottom: 40
                },
                desktop: {
                  top: 40,
                  bottom: 60
                }
              }}
              backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige}>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={10}>
                  <ErrorContainer>
                    <ErrorHeading>
                      {this.renderErrorMessage(this.state.paymentFailMessage)}
                    </ErrorHeading>
                  </ErrorContainer>
                </Grid.Column>
              </Grid>
              <Grid stackable reversed='mobile tablet vertically'>

                {
                  this.state.transactionDetails == undefined ?
                    <Loader active inline='centered' content="Loading..." />
                    :
                    <Grid.Column mobile={16} tablet={16} computer={10}>
                      <Grid>
                        <Grid.Column width={16}>
                          <H2>{heading}</H2>
                        </Grid.Column>
                        <Grid.Column width={16}>
                          {
                            this.state.transactionDetails !== undefined &&
                            <React.Fragment>
                            {isMobile() ?
                              <MobileRetryPayment
                              apiUrl={apiUrl}
                              data={data}
                              guid={this.state.guid}
                              transactionId={this.state.transactionId}
                              previousPaymentType={this.state.selectedPaymentOption}
                              orderCreatedDate={this.state.transactionDetails.CreatedOn}
                              deliveryType={this.state.transactionDetails.DeliveryType}
                              laybyQuote={this.state.transactionDetails.LaybyDetails}
                              isLaybySelected={this.state.transactionDetails?.LaybyDetails != undefined}
                              basket={mapTransactionResponseToBasketResponseModel(this.state.transactionDetails)}
                              swithPaymentOption={this.swithPaymentOption}
                              manufactureNow={this.state.transactionDetails.DeliveryType !== 1}/> :
                            <RetryPayment
                              apiUrl={apiUrl}
                              data={data}
                              guid={this.state.guid}
                              transactionId={this.state.transactionId}
                              previousPaymentType={this.state.selectedPaymentOption}
                              orderCreatedDate={this.state.transactionDetails.CreatedOn}
                              deliveryType={this.state.transactionDetails.DeliveryType}
                              laybyQuote={this.state.transactionDetails.LaybyDetails}
                              isLaybySelected={this.state.transactionDetails?.LaybyDetails != undefined}
                              basket={mapTransactionResponseToBasketResponseModel(this.state.transactionDetails)}
                              swithPaymentOption={this.swithPaymentOption}
                              manufactureNow={this.state.transactionDetails.DeliveryType !== 1}/>}
                            </React.Fragment>
                          }
                        </Grid.Column>
                      </Grid>
                    </Grid.Column>
                }

                <Grid.Column mobile={16} tablet={16} computer={6} floated='right'>
                  {
                    this.state.transactionDetails !== undefined &&
                    <OrderSummary showSurcharge={this.state.selectedPaymentOption == 1}
                      isLayby={this.state.transactionDetails?.LaybyDetails != undefined}
                      laybyQuote={mapTransactionConfirmationToLaybyQuote(this.state.transactionDetails.LaybyDetails)}
                      basket={mapTransactionResponseToBasketResponseModel(this.state.transactionDetails)}
                      apiUrl={apiUrl} giftType={this.state.transactionDetails.GiftTypeId} isRetry={true}/>

                  }
                </Grid.Column>
              </Grid>
            </MobileGenericBannerContainer>
        }

      </Layout>);

  }
}


export default ReviewOrderPage

export const query = graphql`  {
  site {
    siteMetadata {
      apiUrl
    }
  }
    kontentItemReviewPaymentPage {
      elements {
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        url{
          value
        }
        page_info___checkout__title {
          value
        }
        page_info___checkout__progress {
          value {
            codename
            name
          }
        }
        credit_card_note {
          value
        }
        a2a_note {
          value
        }
        credit_card_layby_note{
          value
        }
        a2a_layby_note{
          value
        }
        afterpay_t_c {
          value
        }
        afterpay_note {
          value
        }
        a2a_disable_note {
          value
        }
        afterpay_disable_note {
          value
        }
        layby_disable_note{
          value
        }
        layby_info{
          value
        }
        manufacture_now_description {
          value
        }
        manufacture_later_description {
          value
        }
        manufacture_later_description_layby {
          value
        }
        manufacture_later_description_multiplate {
          value
        }
        gift_a_plate{
          value
        }
        manufacture_later_description_gift {
          value
        }
        standard_t_c{
          value
        }
        manufacture_later_t_c{
          value
        }
        multiorder_t_c{
          value
        }
        dealer_payment_request_note {
          value
        }
        dealer_has_authority_t_c {
          value
        }
        dealers_t_c {
          value
        }
        dealers_customer_t_c {
          value
        }
        dealer_payment_request_t_c{
          value
        }
        manufacture_later_for_dealer {
          value
        }
        gem_note {
          value
        }
      }
    }
  }

  `